const tShirt = ['เสื้อยืด', 'เสื้อยืด Oversize', 'เสื้อแขนกุด', 'กระโปรง'];
const polo = ['เสื้อโปโล'];
const bag = ['กระเป๋าผ้า'];
const pants = ['กางเกง', 'กางเกงขายาว ยางยืด', 'กางเกงขาสั้น เอวยางยืด'];
const arm = ['อาร์มตีนตุ๊กแก', 'อาร์มรีดร้อน'];
const blanket = ['ผ้าห่ม'];
const mask = ['หน้ากาก'];
const sock = ['ถุงเท้า'];
export const productTypes = {
    tShirt,
    polo,
    bag,
    pants,
    arm,
    blanket,
    mask,
    sock,
};
